<template>
  <loading loader="spinner"
    :active="isLoading"
    :can-cancel="true"
    :is-full-page="true"></loading>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">訂單預計出貨明細表</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <!-- <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <select
              v-model="selectedType"
              @change="grid.refresh"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border mr-2"
            >
              <option value="">選擇類型</option>
              <option :value="0">進出口</option>
              <option :value="1">退稅</option>
            </select>
          </div> -->
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white mr-2"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="exportExcel"
            >
              匯出
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent($event, column, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent($event, column, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { computed, defineComponent, ref, Operator, Condition } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import formatDate from 'xe-utils/toDateString'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default defineComponent({
  components: {
    Grid,
    loading: VueLoading,
    // CheckBoxList
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref("");
    const isLoading = ref(false);
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canUpdate = checkPermissions("OrderEstimatedShipmentListUpdate");

    const dateCheck = ({ row }: { row: any }) => {
      if(row.CustomsDeclarationDate) {
        const date = new Date();
        date.setDate(date.getDate() - 548);
        const d = new Date(row.CustomsDeclarationDate);
        return (date > d) ? 'bg-red-100' : '';
      } else {
        return '';
      }
    }

    const gridOptions: GridOptions = {
      id: 'estimatedShipment',
      title: '訂單預計出貨明細表',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canDelete: false,
      canCreate: false,
      canUpdate: canUpdate,
      multiselect: false,
      autoResize: true,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        // { field: 'Type', title: '類型', showHeaderOverflow: false, showOverflow: false, sortable: true,
        //   formatter: ({ cellValue }) => cellValue == 0 ? '進出口' : '退稅'
        // },
        {
          field: "OrderDate",
          title: "訂單日期",
          showHeaderOverflow: false,
          showOverflow: false,
          sortable: true,
          className: dateCheck,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: "EstimatedDate",
          title: "預交日期",
          showHeaderOverflow: false,
          showOverflow: false,
          sortable: true,
          className: dateCheck,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        { field: 'OrderNumber', title: '訂單編號', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },       
        { field: 'CustomerCode', title: '客戶代號', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },
        { field: 'CustomerShortName', title: '客戶簡稱', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },
        { field: 'ProductNumber', title: '品號', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },
        { field: 'CustomerProductNumber', title: '客戶品號', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },
        { field: 'TaxRefundUseName', title: '退稅品名', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },
        { field: 'TaxRefundUseSpecifications', title: '退稅規格', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },
        { field: 'OrderQuantity', title: '訂單數量', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },
        { field: 'BacklogQuantity', title: '未交數量', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },
        { field: 'Uint', title: '單位', showHeaderOverflow: false, showOverflow: false, sortable: false, className: dateCheck },
        { field: "OrderStatus", title: "結案狀態", showHeaderOverflow: false, showOverflow: false, formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderStatus).find(e => e.Value === cellValue)?.Name : undefined, className: dateCheck },
        { field: 'Sales', title: '業務', showHeaderOverflow: false, showOverflow: false, sortable: true, className: dateCheck },
        { field: 'ShipmentQuantity', title: '出貨量換算', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'TaxRefundSemiFinishedProductName', title: '退稅半成品品名', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'TaxRefundSemiFinishedProductSpecifications', title: '退稅半成品規格', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'TaxRefundQuantity', title: '退稅用量', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'TaxRefundDocumentNumber', title: '退稅文號', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'ConsumptionQuantity', title: '實際耗用量', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'ExportTaxRefundDocumentNumber', title: '出口退稅文號', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: "TradeStatus", title: "關貿結案狀態", showHeaderOverflow: false, showOverflow: false, visible: false, formatter: ({ cellValue }) => model ? Object.values(model.enums.OrderStatus).find(e => e.Value === cellValue)?.Name : undefined, className: dateCheck },
        { field: 'ContainerNumber', title: '櫃號', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'ContainerType', title: '櫃型', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'ExportImportTime', title: '進出口時間', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : '', className: dateCheck },    
        { field: 'SO', title: 'SO', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'ShipmentCount', title: '出貨次數', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, className: dateCheck },
        { field: 'CustomsDeclarationDate', title: '報關日期', showHeaderOverflow: false, showOverflow: false, sortable: true, visible: false, formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : '', className: dateCheck },    
      ],
      promises: {
        query: model
          ? async (params) => {
            // if (selectedType.value !== "") {
            //   params.condition!.and("Type", Operator.Equal, selectedType.value);
            // }
            return await model.dispatch('estimatedShipment/query', params);;
        } : undefined,
        queryAll: model ? () => model.dispatch('estimatedShipment/query') : undefined,
        save: model
          ? (params) => model.dispatch('estimatedShipment/save', params)
          : undefined
      },
      modalConfig: { width: "70%", showFooter: true }
    }

    const showInExport = (row: any) => {
      if (row.data.Type == 0) return true;
      return false;
    }

    const showInTaxRefund = (row: any) => {
      if (row.data.Type == 1) return true;
      return false;
    }

    const canEdit = (row: any) => {
      if (row.data.Type == 0) return 'bg-yellow-100';
      return '';
    }

    const showInTaxRefund2 = () => {
      console.log(grid?.value.editingRow)
      const row = grid?.value.editingRow;
      if (row && row.data.Type == 1) return true;
      return false;
    }

    const form = ref<any>(null);
    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'OrderDate',
          title: '訂單日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        },
        {
          field: 'EstimatedDate',
          title: '預交日期',
          span: 12,
          className: canEdit, //'bg-yellow-100',
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', readonly: true } }
        },
        {
          field: 'OrderNumber',
          title: '訂單編號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'CustomerCode',
          title: '客戶代號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'CustomerShortName',
          title: '客戶簡稱',
          span: 12,          
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ProductNumber',
          title: '品號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'CustomerProductNumber',
          title: '客戶品號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundUseName',
          title: '退稅品名',
          className: 'bg-yellow-100',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字'},
            attrs: { type: 'text' }
          }
        },
        {
          field: 'TaxRefundUseSpecifications',
          title: '退稅規格',
          span: 12,
          className: 'bg-yellow-100',
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'OrderQuantity',
          title: '訂單數量',
          span: 12,
          className: 'bg-yellow-100',
          itemRender: {
            name: '$input',
            props: { type: 'number', placeholder: '請輸入數字' }
          }
        },
        {
          field: 'BacklogQuantity',
          title: '未交數量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { type: 'number', placeholder: '請輸入數字', readonly: true }
          }
        },
        {
          field: 'Uint',
          title: '單位',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'OrderStatus',
          title: '結案狀態',
          span: 12,
          // visibleMethod: showInTaxRefund,
          itemRender: { name: "$select", props: { readonly: true, disabled: true }, options: model ? Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Sales',
          title: '業務',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ShipmentQuantity',
          title: '出貨量換算',
          span: 12,
          itemRender: {
            name: '$input',
            props: { type: 'float', placeholder: '請輸入數字', readonly: true }
          },
          // visibleMethod: showInTaxRefund
        },
        {
          field: 'SemiFinishedProductNumber',
          title: '半成品品號',
          span: 12,
          className: 'bg-yellow-100',
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          },
          // visibleMethod: showInTaxRefund
        },
        {
          field: 'TaxRefundSemiFinishedProductName',
          title: '退稅半成品品名',
          span: 12,
          className: 'bg-yellow-100',
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          },
          // visibleMethod: showInTaxRefund
        },
        {
          field: 'TaxRefundSemiFinishedProductSpecifications',
          title: '退稅半成品規格',
          span: 12,
          className: 'bg-yellow-100',
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字'},
            attrs: { type: 'text' }
          },
          // visibleMethod: showInTaxRefund
        },
        {
          field: 'TaxRefundQuantity',
          title: '退稅用量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { type: 'double', placeholder: '請輸入數字', readonly: true }
          },
          // visibleMethod: showInTaxRefund
        },
        {
          field: 'TaxRefundDocumentNumber',
          title: '退稅文號',
          span: 12,
          className: 'bg-yellow-100',
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          },
          // visibleMethod: showInTaxRefund
        },
        {
          field: 'ConsumptionQuantity',
          title: '實際耗用量',
          span: 12,
          itemRender: {
            name: '$input',
            props: { type: 'double', placeholder: '請輸入數字', readonly: true }
          },
          // visibleMethod: showInTaxRefund
        },
        {
          field: 'ExportTaxRefundDocumentNumber',
          title: '出口退稅文號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          },
          // visibleMethod: showInTaxRefund
        },
        {
          field: 'TradeStatus',
          title: '關貿結案狀態',
          span: 12,
          itemRender: { name: "$select", props: { readonly: true, disabled: true }, options: model ? Object.values(model.enums.OrderStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'ContainerNumber',
          title: '櫃號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          },
          className: 'bg-yellow-100',
          // visibleMethod: showInExport
        },
        {
          field: 'ContainerType',
          title: '櫃型',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' },
            attrs: { type: 'text' }
          },
          className: 'bg-yellow-100',
          // visibleMethod: showInExport
        },
        {
          field: 'ExportImportTime',
          title: '進出口時間',
          span: 12,
          className: 'bg-yellow-100',
          // visibleMethod: showInExport,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'SO',
          title: 'SO',
          span: 12,
          // className: canEdit,
          className: 'bg-yellow-100',
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字' }, //, readonly: true
            attrs: { type: 'text' }
          },
          // visibleMethod: showInExport
        },
        {
          field: 'ShipmentCount',
          title: '出貨次數',
          span: 12,
          itemRender: {
            name: '$input',
            props: { type: 'number', placeholder: '請輸入數字', readonly: true }
          }
        },
      ],
      // rules: {
      //   Name: [{ type: 'string', required: true }]
      // }
    }

    const onGridEdit = async (row: any, callback: any) => {
      console.log(row.Type)
      var item = formOptions.items![1];
      if (item.itemRender) {
        var render = item.itemRender;
        render.props!.readonly = row.Type == 0 ? false : true;
      }
      callback()
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async() => {      
      // if (selectedType.value !== "") {
      //   condition.and("Type", Operator.Equal, selectedType.value);
      // }
      try {
        var condition = new Condition();
        isLoading.value = true;
        var data = await model!.dispatch('estimatedShipment/exportExcel', {keyword: grid.value.keyword, condition: condition});
        // console.log(data);
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // console.log(file)
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "訂單預計出貨明細表.xlsx";
        link.click();
        //document.body.removeChild(link);
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    return {
      grid,
      form,
      gridOptions,
      formOptions,
      selectedType,
      onGridEdit,
      exportExcel,
      isLoading,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
